/*
this files is included in the webpack.common.js file of the main theme
*/


/* 
small dropdown shopping basket 
*/

function isElementOutOfView(selector) {
    var element = selector;
    if (!element) {
        console.error(`Element with selector "${selector}" not found.`);
        return false;
    }

    var rect = element.getBoundingClientRect();

    // Check if the element is out of view
    var isOutOfView = (
        rect.bottom < 0 || // element is above the viewport
        rect.top > (window.innerHeight || document.documentElement.clientHeight) || // element is below the viewport
        rect.right < 0 || // element is to the left of the viewport
        rect.left > (window.innerWidth || document.documentElement.clientWidth) // element is to the right of the viewport
    );

    return isOutOfView;
}

function checkElementVisibilityOnScroll(selector) {
    let cart = document.querySelector('.cart');
    window.addEventListener('scroll', function () {
        if (isElementOutOfView(selector)) {
            //console.log('Element is out of view.');
            if (!cart.classList.contains('position-fixed')) {
                cart.classList.add('position-fixed');
            }
            
            //selector.classList.add('position-fixed');
        } else {
            //console.log('Element is in view.');
            if (cart.classList.contains('position-fixed')) {
                cart.classList.remove('position-fixed');
            }
            
        }
    });
}




document.addEventListener('DOMContentLoaded', () => {
    //check existance
    if (document.body.classList.contains('single-product')) {
        //let cart = document.querySelector('.cart');
        // Usage
        //var selector = '.cart'; // or '#yourElementId' or any other valid CSS selector
        let selector = document.querySelector('.custom-product-image');

        checkElementVisibilityOnScroll(selector);

        // Initial check without scrolling
        
    }
});



function reinnitCartAjax() {
    cart_item_quantity_change();
    remove_cart_item();
    
}
var cartContentsCount;
let loader_popup;
let product_title;
let st = null;
// Function to update cart item quantity via AJAX
function updateCartItemQuantity(cartItemKey, quantity) {
    var xhr = new XMLHttpRequest();
    xhr.open('POST', cart_params.ajax_url);
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    xhr.onload = function () {
        if (xhr.status >= 200 && xhr.status < 400) {
            var response = JSON.parse(xhr.responseText);
            if (response.success) {
                updateCartCount();
            } else {
                alert('Could not update cart item quantity.');
                updateCartCount();
            }
        } else {
            alert('Server error. Please try again later.');
        }
    };
    xhr.send('action=update_cart_item_quantity&cart_item_key=' + encodeURIComponent(cartItemKey) + '&quantity=' + encodeURIComponent(quantity));
}

// Function to update cart count in the menu
function updateCartCount() {
    
    //cartContentsCount.classList.add('updating');
    var xhr = new XMLHttpRequest();
    xhr.open('GET', cart_params.ajax_url + '?action=get_cart_count');
    xhr.onload = function () {
        if (xhr.status >= 200 && xhr.status < 400) {
            cartContentsCount.textContent = xhr.responseText;
            if (xhr.responseText == '0') {
                document.querySelector('.menu-item-type-cart').classList.add('cc-0');    
            }
            cartContentsCount.classList.remove('updating');
            

        } else {
            alert('Server error. Please try again later.');
        }
    };
    xhr.send();
}


document.addEventListener('DOMContentLoaded', function () {
    
    remove_cart_item();
   
});
function remove_cart_item() {
    // Remove cart item handler
    cartContentsCount = document.querySelector('.cart-contents-count');
    document.querySelectorAll('.remove-cart-item').forEach(function (button) {
        button.addEventListener('click', function (e) {
            e.preventDefault();
            cartContentsCount.classList.add('updating');
            var cartItemKey = this.dataset.cartItemKey;
            var item = this.closest('.menu-item');

            var xhr = new XMLHttpRequest();
            xhr.open('POST', cart_params.ajax_url);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
            xhr.onload = function () {
                if (xhr.status >= 200 && xhr.status < 400) {
                    var response = JSON.parse(xhr.responseText);

                    if (response.success) {
                        item.remove();
                        updateCartCount();
                    } else {
                        alert('Could not remove cart item.');
                    }
                } else {
                    alert('Server error. Please try again later.');
                }
            };
            xhr.send('action=remove_cart_item&cart_item_key=' + encodeURIComponent(cartItemKey));


        });
    });
}
document.addEventListener('DOMContentLoaded', function () {
    update_mini_cart();
    cart_item_quantity_change();
    loader_popup = document.querySelector('.add-to-cart-popup');
    let cp = document.querySelector('.close-popup');
    if (cp) {
        cp.addEventListener('click', (e) => {
            e.preventDefault();
            loader_popup.classList.toggle('d-flex');
        })
    
        loader_popup.addEventListener('click', (e) => {
            e.preventDefault();
            //loader_popup.classList.toggle('d-flex');
            console.log(e.target);
            let target = e.target;
            if (e.target.classList.contains('add-to-cart-popup')) {
                console.log('hide_loader');
                loader_popup.classList.remove('d-flex');
            }
            if (e.target.classList.contains('to-shopping-cart')) {
                console.log(e.target.getAttribute('href'));
                document.location = e.target.getAttribute('href');
            }
            if (e.target.classList.contains('btn-text')) {
                console.log(e.target.getAttribute('href'));
                document.location = e.target.getAttribute('href');
            }
        })
    }
   
});

function cart_item_quantity_change() {
    // Event listener for cart item quantity change
    document.querySelectorAll('.cart-item-qty').forEach(function (input) {
        input.addEventListener('change', function (e) {
            e.preventDefault();
            if (st) {
                clearTimeout(st);
            }

            var cartItemKey = this.dataset.cartItemKey;
            var quantity = this.value;

            st = setTimeout(() => { 
                cartContentsCount.classList.add('updating');
                
                updateCartItemQuantity(cartItemKey, quantity);

            }, 250);
        });
    });
}

function show_loader() {
    loader_popup.classList.add('d-flex');
    loader_popup.querySelector('.spinner').classList.remove('d-none');
    loader_popup.querySelector('.btn-wr').classList.remove('d-flex');
    loader_popup.querySelector('.product-title').innerHTML= '- '+product_title;
}
function hide_loader(status) {
    loader_popup.querySelector('.spinner').classList.add('d-none');
    loader_popup.querySelector('.btn-wr').classList.toggle('d-flex');
    if (status == 'error') {
        loader_popup.querySelector('h2').classList.add('d-none');
        loader_popup.querySelector('.product-title').innerHTML = '<span class="error"><strong>LET OP!</strong><br /></span>';
    } else {
        loader_popup.querySelector('h2').classList.remove('d-none');
    }
}
/*
Ajax actions product overview page: add to cart Ajax
*/
document.addEventListener('DOMContentLoaded', function () {
    const buttons = document.querySelectorAll('.ajax-add-to-cart');

    buttons.forEach(button => {
        button.addEventListener('click', function (e) {
            e.preventDefault();
            product_title = e.target.getAttribute('data-title');
            show_loader();
            

            const productId = button.getAttribute('data-product_id');

            const data = `action=ajax_add_to_cart&product_id=${productId}`;
            //console.log(cart_params);
            fetch(cart_params.ajax_url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: data
            })
                .then(response => response.json())
                .then(result => {
                    if (result.success) {
                        // Optionally update cart count or show a message
                        //alert('Product added to cart:' + product_title);
                        update_mini_cart();
                        hide_loader('succes');
                    } else {
                        hide_loader('error');
                        console.log('dikke vette error');
                        console.log(result.data.message);
                        Object.values(result.data.message).forEach(value => {
                            document.querySelector('.add-to-cart-popup .product-title').innerHTML+=value.notice;
                        });
                        //alert('Failed to add product to cart');
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                    hide_loader('error');
                    alert('An error occurred: ' + error.message);
                });
        });
    });
});

/*
update mini cart
*/
/**
 * Update mini cart
 */
function update_mini_cart() { 
    const data = `action=update_menu_cart`;
    console.log('u-m-c');
    fetch(cart_params.ajax_url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: data
    })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.text(); // Parse response as text since it's HTML
        })
        .then(html => {
            // Handle the HTML response
            // Assuming you want to replace an element with the new HTML
            //const cartElement = document.querySelector('.menu-item-type-cart');
            const cartElements = document.querySelectorAll('.shopping-cart-dd');
            if (cartElements.length > 0) {
                // Create a temporary div to hold the new content
                // const tempDiv = document.createElement('div');
                // tempDiv.innerHTML = html;
                // const newContent = tempDiv.firstElementChild;

                // Loop over each cart element and replace its content
                cartElements.forEach(cartElement => {
                    //cartElement.replaceWith(newContent.cloneNode(true));
                    cartElement.innerHTML = html;
                });

                reinnitCartAjax();
            }

        })
        .catch(error => console.error('Error:', error));
}

// if ('serviceWorker' in navigator) {
//     window.addEventListener('load', () => {
//         navigator.serviceWorker.register('/service-worker-v12.js').then(registration => {
//             console.log('Service Worker registered with scope:', registration.scope);

//             // Check for updates
//             registration.update();

//             // Listen for updates to the Service Worker.
//             if (registration.waiting) {
//                 registration.waiting.postMessage({ type: 'SKIP_WAITING' });
//             }

//             // If a new service worker is found, tell it to activate immediately.
//             registration.addEventListener('updatefound', () => {
//                 const newWorker = registration.installing;
//                 if (newWorker) {
//                     newWorker.addEventListener('statechange', () => {
//                         if (newWorker.state === 'installed' && navigator.serviceWorker.controller) {
//                             newWorker.postMessage({ type: 'SKIP_WAITING' });
//                         }
//                     });
//                 }
//             });
//         }).catch(error => {
//             console.error('Service Worker registration failed:', error);
//         });

//         // Reload the page to ensure the new service worker is controlling the page.
//         let refreshing;
//         navigator.serviceWorker.addEventListener('controllerchange', () => {
//             if (!refreshing) {
//                 window.location.reload();
//                 refreshing = true;
//             }
//         });
//     });
// }






