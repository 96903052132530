/* HEKTOR DEVELOP */
import 'bootstrap';



const body_ = document.body;
document.addEventListener('DOMContentLoaded', () => {
  body_.classList.add('loaded');
  const main_nav = document.querySelector('.main-nav #button');
  const main_nav_close = document.querySelector('.main-nav #close');

  main_nav.addEventListener('click', () => { 
    toggleMenu();
  })
  main_nav_close.addEventListener('click', () => {
    toggleMenu();
  })
  


  // jQuery('.main-nav #button').on('click', function () {
  //   toggleMenu();
  // });

  // jQuery('.main-nav #close').on('click', function () {
  //   toggleMenu();
  // });

});






function toggleMenu() {
  body_.classList.toggle('menu-show');
}

// $(function(){

//   // jQuery methods go here...
//   //alert('test 123');
//   $('.menu-item-has-children > a').on('click',function(e){
//     console.log(e.target);
//     console.log($(this));
//     $(this).next().toggleClass('open');
//     //alert('test 1234');
//     e.preventDefault();
//   });

//   // $('.yoga-data').on('mouseover',function(){
//   //   alert('koekeok!');
//   //   alert($(this).data('excerpt'));
//   // });
//   // new Tooltip($('.yoga-data'), {
//   //   placement: 'top', // or bottom, left, right, and variations
//   //   title: this.getAttribute('data-excerpt'),
//   //   html:true
//   // });


// });


// $(function () {
//   $('[data-toggle="tooltip"]').tooltip({ boundary: 'window' });
// });

// document.addEventListener('DOMContentLoaded',function(){
//   var trigger = document.getElementsByClassName("yoga-data");
//   var instance = new Tooltip(trigger,{
//     title: trigger.getAttribute('data-excerpt'),
//     trigger: "hover",
//   });
// });



// function waitForElement(elementPath, callBack){
//   window.setTimeout(function(){
//     if($(elementPath).length){
//       callBack(elementPath, $(elementPath));
//     }else{
//       waitForElement(elementPath, callBack);
//     }
//   },500)
// }


// waitForElement(".momoyoga-lesson-teacher",function(){
//     console.log("done loading yeah!");
//     $('.momoyoga-lesson-teacher').each(function(index){
//         console.log( index + ": " + $( this ).text() );
//         var parent = $(this).parent();
//         var url = '<a href="/lessen/docenten/?name='+ $( this ).text()+'">' + $( this ).text()+'</a>';
//         $(this).html(url);
//         $('.momoyoga-lesson-details p:eq(1)',parent).html('<strong>Leraar</strong>'+url);
//     });

// });


//plugin scrollEnd





// var editor = CodeMirror.fromTextArea(document.getElementById("code"), {
//     theme: 'default',
//     lineNumbers: true,
//     styleActiveLine: true
//   });










//scroll
// jQuery(function() {
//   jQuery('a[href*="#"]:not([href="#"])').click(function() {
//     console.log('scrolled!');
//     if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
//       var target = jQuery(this.hash);
//       target = target.length ? target : jQuery('[name=' + this.hash.slice(1) +']');
//       if (target.length) {
//         jQuery('html, body').animate({
//           scrollTop: target.offset().top
//         }, 1000);
//         return false;
//       }
//     }
//   });
// });







